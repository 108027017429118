$(document).foundation();



var FirstSide = $('#firstside');
var SecondSide = $('#secondside');
var ContactHead = $('#contactbuttonhead');
var ContactHeadSmall = $('#contactbuttonheadsmall');
var ContactHead2 = $('#contactbutton');
var ContactHead3 = $('#close');
var ContactHead4 = $('#closesmall');
var TextLeft = $('#projecttextleft1');
var TextLeft2 = $('#projecttextleft2');
var TextLeft3 = $('#projecttextleft3');
var TextRight = $('#projecttextright1');
var TextRight2 = $('#projecttextright2');
var TextRight3 = $('#projecttextright3');
var Project = $('#project');
var ContactSection = $('#contactsection');
var ContactSectionSmall = $('#contactsectionsmall');
var windowWidth = $( window ).width();
var windowHeight = $( window ).height();

var isMenuOpen = false; 


TweenMax.set(FirstSide,{x: windowWidth, alpha: 0});
TweenMax.set(SecondSide,{x: -windowWidth, alpha: 0});
TweenMax.set(ContactHead,{alpha: 0});
TweenMax.set(ContactHeadSmall,{alpha: 0});
TweenMax.set(TextLeft,{alpha: 0, x:-200});
TweenMax.set(TextLeft2,{alpha: 0, x:-200});
TweenMax.set(TextLeft3,{alpha: 0, x:-200});
TweenMax.set(TextRight,{alpha: 0, x:200});
TweenMax.set(TextRight2,{alpha: 0, x:200});
TweenMax.set(TextRight3,{alpha: 0, x:200});

window.onload = function() {

  var timeline = new TimelineMax();
  timeline.to(FirstSide, 1.5, {ease: Power3.easeOut, x:0, alpha: 1}, 0)
    .to(SecondSide, 1.5, {ease: Power3.easeOut, x:0, alpha: 1})
    .add("end", 3)
    .to(ContactHead, 1.5, {alpha: 1}, "end")
    .to(ContactHeadSmall, 1.5, {alpha: 1}, "end")

 
  
  
}


var navButtonsTL = new TimelineMax();

ContactHead.on('click', slideMenu);
ContactHeadSmall.on('click', slideMenuSmall);

function slideMenu(){
  
    TweenMax.to(ContactSection, 0.25, {x: -500});
 

  //   // slide in buttons
  // navContainerTL.staggerFrom(navContainer,0.5,{paddingLeft:350, alpha:0, ease:Linear.easeNone}, 0.25);
   
   
  }

  function slideMenuSmall(){
  
    TweenMax.to(ContactSectionSmall, 0.25, {x: -300});
 

  //   // slide in buttons
  // navContainerTL.staggerFrom(navContainer,0.5,{paddingLeft:350, alpha:0, ease:Linear.easeNone}, 0.25);
   
   
  }

ContactHead3.on('click', slideMenuBack);
ContactHead4.on('click', slideMenuBackSmall);


function slideMenuBack(){
  
    TweenMax.to(ContactSection, 0.25, {x: 0});
 

  //   // slide in buttons
  // navContainerTL.staggerFrom(navContainer,0.5,{paddingLeft:350, alpha:0, ease:Linear.easeNone}, 0.25);
   
   
  }

  function slideMenuBackSmall(){
  
    TweenMax.to(ContactSectionSmall, 0.25, {x: 0});
 

  //   // slide in buttons
  // navContainerTL.staggerFrom(navContainer,0.5,{paddingLeft:350, alpha:0, ease:Linear.easeNone}, 0.25);
   
   
  }

  




// var vigilwaypoint1old = new Waypoint({
//   	element: $('#secondside'),
//   	handler: function() {
//   		console.log("top of section 2 reached");

//   		// this will happen everytime the section 2 top hits the top of the view port
//     	// TweenMax.from(alfImage, 1,{alpha:0, y:300});

//     	// to make it only fade in once we have to use to instead of from, becuse of that we need to set the initial properties of the image
//     	TweenMax.to(TextLeft, 1.5,{ease: Power3.easeOut, alpha:1, x:-50});
//   	}
  	
// });



// var fatmonkwaypoint1old = new Waypoint({
//   	element: $('#projectright1'),
//   	handler: function() {
//   		console.log("top of section 2 reached");

//   		// this will happen everytime the section 2 top hits the top of the view port
//     	// TweenMax.from(alfImage, 1,{alpha:0, y:300});

//     	// to make it only fade in once we have to use to instead of from, becuse of that we need to set the initial properties of the image
//     	TweenMax.to(TextRight, 1.5,{ease: Power3.easeOut, alpha:1, x:0});
//   	}
  	
// });


// var strangerwaypoint1old = new Waypoint({
//   	element: $('#projectleft1'),
//   	handler: function() {
//   		console.log("top of section 2 reached");

//   		// this will happen everytime the section 2 top hits the top of the view port
//     	// TweenMax.from(alfImage, 1,{alpha:0, y:300});

//     	// to make it only fade in once we have to use to instead of from, becuse of that we need to set the initial properties of the image
//     	TweenMax.to(TextLeft2, 1.5,{ease: Power3.easeOut, alpha:1, x:-50});
//   	}
  	
// });

// var repowaypoint1old = new Waypoint({
//   	element: $('#projectright2'),
//   	handler: function() {
//   		console.log("top of section 2 reached");

//   		// this will happen everytime the section 2 top hits the top of the view port
//     	// TweenMax.from(alfImage, 1,{alpha:0, y:300});

//     	// to make it only fade in once we have to use to instead of from, becuse of that we need to set the initial properties of the image
//     	TweenMax.to(TextRight2, 1.5,{ease: Power3.easeOut, alpha:1, x:0});
//   	}
  	
// });

// var teawaypoint1old = new Waypoint({
//   	element: $('#projectleft2'),
//   	handler: function() {
//   		console.log("top of section 2 reached");

//   		// this will happen everytime the section 2 top hits the top of the view port
//     	// TweenMax.from(alfImage, 1,{alpha:0, y:300});

//     	// to make it only fade in once we have to use to instead of from, becuse of that we need to set the initial properties of the image
//     	TweenMax.to(TextLeft3, 1.5,{ease: Power3.easeOut, alpha:1, x:-50});
//   	}
  	
// });


// // this inview will control the image animation on the scroll UP direction
var vigilwaypoint1 = new Waypoint.Inview({
  	element: $('#secondside')[0],
  	enter: function(direction) {
  		//console.log("enter " + direction);
  		// if the direction is equal to up, fade IN the alf photo
  		if(direction === "up"){
  			TweenMax.to(TextLeft, 1.5,{ease: Power3.easeOut, alpha:1, x:-50});
  		}
  	},
  	entered: function(){ 
  		// console.log("entered inview 2");
  	},
  	exit: function(direction) {
  		console.log("exit " + direction);
  		// if the direction is equal to up, fade OUT the alf photo
  		if(direction === "up"){
  			//console.log("fade out alf");
  			TweenMax.to(TextLeft, 1.5,{ease: Power3.easeOut, alpha:0, x:-200});
  		}
  	},
  	exited: function() {
  		// console.log("exited inview 2");
  	}
});

// this inview will control the image animation on the scroll DOWN direction
var vigilwaypoint2 = new Waypoint.Inview({
  	element: $('#projectleft1')[0],
  	enter: function( direction) {
  		console.log("enter " + direction);
  		if(direction === "down"){
  			//console.log("fade out alf");
  			TweenMax.to(TextLeft, 1.5,{ease: Power3.easeOut, alpha:1, x:-50});
  		}
  	},
  	entered: function(){
  	},
  	exit: function(direction) {
  		console.log("enter " + direction);
  		if(direction === "down"){
  			//console.log("fade out alf");
  			// I moved this -300 so you can't see it fade away as you scroll
  			TweenMax.to(TextLeft, 1.5,{ease: Power3.easeOut, alpha:0, x:-200});

  		}
  	},
  	exited: function() {
  	}
});

// // this inview will control the image animation on the scroll UP direction
var fatmonkwaypoint1 = new Waypoint.Inview({
  	element: $('#projectright1')[0],
  	enter: function(direction) {
  		//console.log("enter " + direction);
  		// if the direction is equal to up, fade IN the alf photo
  		if(direction === "up"){
  			TweenMax.to(TextRight, 1.5,{ease: Power3.easeOut, alpha:1, x:-50});
  		}
  	},
  	entered: function(){ 
  		// console.log("entered inview 2");
  	},
  	exit: function(direction) {
  		console.log("exit " + direction);
  		// if the direction is equal to up, fade OUT the alf photo
  		if(direction === "up"){
  			//console.log("fade out alf");
  			TweenMax.to(TextRight, 1.5,{ease: Power3.easeOut, alpha:0, x:-200});
  		}
  	},
  	exited: function() {
  		// console.log("exited inview 2");
  	}
});

// this inview will control the image animation on the scroll DOWN direction
var fatmonkwaypoint2 = new Waypoint.Inview({
  	element: $('#projectright2')[0],
  	enter: function( direction) {
  		console.log("enter " + direction);
  		if(direction === "down"){
  			//console.log("fade out alf");
  			TweenMax.to(TextRight, 1.5,{ease: Power3.easeOut, alpha:1, x:-50});
  		}
  	},
  	entered: function(){
  	},
  	exit: function(direction) {
  		console.log("enter " + direction);
  		if(direction === "down"){
  			//console.log("fade out alf");
  			// I moved this -300 so you can't see it fade away as you scroll
  			TweenMax.to(TextRight, 1.5,{ease: Power3.easeOut, alpha:0, x:-200});
  			
  		}
  	},
  	exited: function() {
  	}
});


// // this inview will control the image animation on the scroll UP direction
var strangerwaypoint1 = new Waypoint.Inview({
  	element: $('#projectleft1')[0],
  	enter: function(direction) {
  		//console.log("enter " + direction);
  		// if the direction is equal to up, fade IN the alf photo
  		if(direction === "up"){
  			TweenMax.to(TextLeft2, 1.5,{ease: Power3.easeOut, alpha:1, x:-50});
  		}
  	},
  	entered: function(){ 
  		// console.log("entered inview 2");
  	},
  	exit: function(direction) {
  		console.log("exit " + direction);
  		// if the direction is equal to up, fade OUT the alf photo
  		if(direction === "up"){
  			//console.log("fade out alf");
  			TweenMax.to(TextLeft2, 1.5,{ease: Power3.easeOut, alpha:0, x:-200});
  		}
  	},
  	exited: function() {
  		// console.log("exited inview 2");
  	}
});

// this inview will control the image animation on the scroll DOWN direction
var strangerwaypoint2 = new Waypoint.Inview({
  	element: $('#projectleft2')[0],
  	enter: function( direction) {
  		console.log("enter " + direction);
  		if(direction === "down"){
  			//console.log("fade out alf");
  			TweenMax.to(TextLeft2, 1.5,{ease: Power3.easeOut, alpha:1, x:-50});
  		}
  	},
  	entered: function(){
  	},
  	exit: function(direction) {
  		console.log("enter " + direction);
  		if(direction === "down"){
  			//console.log("fade out alf");
  			// I moved this -300 so you can't see it fade away as you scroll
  			TweenMax.to(TextLeft2, 1.5,{ease: Power3.easeOut, alpha:0, x:-200});
  			
  		}
  	},
  	exited: function() {
  	}
});


// // this inview will control the image animation on the scroll UP direction
var repowaypoint1 = new Waypoint.Inview({
  	element: $('#projectright2')[0],
  	enter: function(direction) {
  		//console.log("enter " + direction);
  		// if the direction is equal to up, fade IN the alf photo
  		if(direction === "up"){
  			TweenMax.to(TextRight2, 1.5,{ease: Power3.easeOut, alpha:1, x:-50});
  		}
  	},
  	entered: function(){ 
  		// console.log("entered inview 2");
  	},
  	exit: function(direction) {
  		console.log("exit " + direction);
  		// if the direction is equal to up, fade OUT the alf photo
  		if(direction === "up"){
  			//console.log("fade out alf");
  			TweenMax.to(TextRight2, 1.5,{ease: Power3.easeOut, alpha:0, x:-200});
  		}
  	},
  	exited: function() {
  		// console.log("exited inview 2");
  	}
});

// this inview will control the image animation on the scroll DOWN direction
var repowaypoint2 = new Waypoint.Inview({
  	element: $('#projectright3')[0],
  	enter: function( direction) {
  		console.log("enter " + direction);
  		if(direction === "down"){
  			//console.log("fade out alf");
  			TweenMax.to(TextRight2, 1.5,{ease: Power3.easeOut, alpha:1, x:-50});
  		}
  	},
  	entered: function(){
  	},
  	exit: function(direction) {
  		console.log("enter " + direction);
  		if(direction === "down"){
  			//console.log("fade out alf");
  			// I moved this -300 so you can't see it fade away as you scroll
  			TweenMax.to(TextRight2, 1.5,{ease: Power3.easeOut, alpha:0, x:-200});
  			
  		}
  	},
  	exited: function() {
  	}
});


// // this inview will control the image animation on the scroll UP direction
var teawaypoint1 = new Waypoint.Inview({
  	element: $('#projectleft2')[0],
  	enter: function(direction) {
  		//console.log("enter " + direction);
  		// if the direction is equal to up, fade IN the alf photo
  		if(direction === "up"){
  			TweenMax.to(TextLeft3, 1.5,{ease: Power3.easeOut, alpha:1, x:-50});
  		}
  	},
  	entered: function(){ 
  		// console.log("entered inview 2");
  	},
  	exit: function(direction) {
  		console.log("exit " + direction);
  		// if the direction is equal to up, fade OUT the alf photo
  		if(direction === "up"){
  			//console.log("fade out alf");
  			TweenMax.to(TextLeft3, 1.5,{ease: Power3.easeOut, alpha:0, x:-200});
  		}
  	},
  	exited: function() {
  		// console.log("exited inview 2");
  	}
});

// this inview will control the image animation on the scroll DOWN direction
var teawaypoint2 = new Waypoint.Inview({
  	element: $('#projectleft3')[0],
  	enter: function( direction) {
  		console.log("enter " + direction);
  		if(direction === "down"){
  			//console.log("fade out alf");
  			TweenMax.to(TextLeft3, 1.5,{ease: Power3.easeOut, alpha:1, x:-50});
  		}
  	},
  	entered: function(){
  	},
  	exit: function(direction) {
  		console.log("enter " + direction);
  		if(direction === "down"){
  			//console.log("fade out alf");
  			// I moved this -300 so you can't see it fade away as you scroll
  			TweenMax.to(TextLeft3, 1.5,{ease: Power3.easeOut, alpha:0, x:-200});
  			
  		}
  	},
  	exited: function() {
  	}
});

// // this inview will control the image animation on the scroll UP direction
var saganwaypoint1 = new Waypoint.Inview({
    element: $('#projectright3')[0],
    enter: function(direction) {
      //console.log("enter " + direction);
      // if the direction is equal to up, fade IN the alf photo
      if(direction === "up"){
        TweenMax.to(TextRight3, 1.5,{ease: Power3.easeOut, alpha:1, x:-50});
      }
    },
    entered: function(){ 
      // console.log("entered inview 2");
    },
    exit: function(direction) {
      console.log("exit " + direction);
      // if the direction is equal to up, fade OUT the alf photo
      if(direction === "up"){
        //console.log("fade out alf");
        TweenMax.to(TextRight3, 1.5,{ease: Power3.easeOut, alpha:0, x:-200});
      }
    },
    exited: function() {
      // console.log("exited inview 2");
    }
});

// this inview will control the image animation on the scroll DOWN direction
var saganwaypoint2 = new Waypoint.Inview({
    element: $('#projectleft3')[0],
    enter: function( direction) {
      console.log("enter " + direction);
      if(direction === "down"){
        //console.log("fade out alf");
        TweenMax.to(TextRight3, 1.5,{ease: Power3.easeOut, alpha:1, x:-50});
      }
    },
    entered: function(){
    },
    // exit: function(direction) {
    //   console.log("enter " + direction);
    //   if(direction === "down"){
    //     //console.log("fade out alf");
    //     // I moved this -300 so you can't see it fade away as you scroll
    //     TweenMax.to(TextRight3, 1.5,{ease: Power3.easeOut, alpha:0, x:-200});
        
    //   }
    // },
    // exited: function() {
    // }
});


$(".myBox").click(function() {
  window.location = $(this).find("a").attr("href"); 
  return false;
});





